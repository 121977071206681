import request from '@/utils/request'

/**
 * 查询城区
 */
export const findGuidepriceByDistrict = (data) => {
  return request({
    url: '/guideprice/findGuidepriceByDistrict',
    method: 'POST',
    data
  })
}
// 根据社区查询小区列表
export const findGuidepriceByCommunity = (data) => {
  return request({
    url: '/guideprice/findGuidepriceByCommunity',
    method: 'POST',
    data
  })
}
// 查询参考租金（小区）
export const findGuidepriceByProject = (data) => {
  return request({
    url: '/guideprice/findGuidepriceByProject',
    method: 'POST',
    data
  })
}
// 查询搜索次数
export const countGuidepriceByProject = (data) => {
  return request({
    url: '/guideprice/countGuidepriceByProject',
    method: 'POST',
    data
  })
}
