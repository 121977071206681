<template>
  <div style='height: 100%; width: 100%'>
    <nesi-user-common :title='`参考租金查询`'>
      <template #button>
        <div class='btn-wrapper'>
          <el-form
            :inline='true'
            :rules='rules'
            :model='formData'
            ref='formRef'
          >
            <el-form-item label-width='150px' class='m-2' label='区域'>
              <el-select
                v-model='districtName'
                placeholder='请选择'
                size='large'
                @change='QYChange'
              >
                <el-option
                  v-for='item in districtNameArr'
                  :key='item.districtName'
                  :label='item.districtName'
                  :value='item.districtName'
                />
              </el-select>
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='街道'>
              <el-select
                v-model='streetName'
                @change='streetChange'
                placeholder='请选择'
                size='large'
              >
                <el-option
                  v-for='(item, index) in data'
                  :key='item.id'
                  :label='item.street'
                  :value='index'
                />
              </el-select>
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='社区'>
              <el-select
                v-model='communityName'
                placeholder='请选择'
                size='large'
                @change='communityChange'
              >
                <el-option
                  v-for='(item, index1) in communityArr'
                  :key='item.id'
                  :label='item.community'
                  :value='index1'
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label-width='150px'
              class='m-2'
              label='小区'
              prop='searchInput'
            >
              <el-select
                v-model='formData.searchInput'
                size='large'
                filterable
                placeholder='请选择小区'
              >
                <el-option
                  v-for='item in projectArr'
                  :key='item.project_name'
                  :label='item.project_name'
                  :value='item.project_name'
                />
              </el-select>
            </el-form-item>
          </el-form>
          <div class='butModel'>
            <span v-if='count'
            >每月可查询3个小区，本月剩余查询<span class='count'>{{
                countNum - count > 0 ? countNum - count : 0
              }}</span
            >小区</span
            >
            <el-button class='but' @click='find' type='primary'>查询</el-button>
          </div>
        </div>
      </template>
      <!-- <template #query> </template> -->
      <template #content>
        <div class='box' v-show='isShow && showData'>
          <el-form style='margin-left: 20px' :inline='true'>
            <el-form-item label-width='150px' class='m-2' label='项目名称'>
              <el-input :value='showData.project_name' :disabled='true' />
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='城区'>
              <el-input :value='showData.district' :disabled='true' />
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='街道'>
              <el-input :value='showData.street' :disabled='true' />
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='社区'>
              <el-input :value='showData.community' :disabled='true' />
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='租金年份'>
              <el-input :value='showData.year' :disabled='true' />
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='楼梯楼'>
              <el-input
                :value='showData.stairway_type_base '
                :disabled='true'
              />
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='电梯楼'>
              <el-input
                :value='showData.lift_type_base'
                :disabled='true'
              />
            </el-form-item>
            <el-form-item label-width='150px' class='m-2' label='集体住宅'>
              <el-input
                :value=' showData.collective_housing_type_base'
                :disabled='true'
              />
            </el-form-item>
          </el-form>
        </div>
      </template>
    </nesi-user-common>
  </div>
</template>

<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import {
  findGuidepriceByDistrict,
  findGuidepriceByCommunity,
  findGuidepriceByProject,
  countGuidepriceByProject
} from '@/api/referenceRent.js'
import {
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
  defineComponent,
  computed,
  watch
} from 'vue'

defineComponent({
  NesiUserCommon
})
onMounted(() => {
  getFindGuidepriceByDistrict(districtName.value)
  getCount()
})
// 选择器
const value = ref('')
const noValue = ref('暂无')
const rules = {
  // searchInput: [{ required: true, message: '请选择小区', trigger: 'blur' }]
}
// 区域选择器
const districtNameArr = reactive([
  {
    districtName: '青秀区'
  },
  {
    districtName: '西乡塘区'
  },
  {
    districtName: '兴宁区'
  },
  {
    districtName: '武鸣区'
  },
  {
    districtName: '江南区'
  },
  {
    districtName: '经开区'
  },
  {
    districtName: '良庆区'
  },
  {
    districtName: '邕宁区'
  },
  {
    districtName: '东盟经开区'
  },
  {
    districtName: '高新区'
  }
])
const districtName = ref('青秀区')

// 区域改变监听
function QYChange() {
  // console.log(districtName)
  getFindGuidepriceByDistrict(districtName.value)
}

const formData = reactive({
  searchInput: ''
})
const data = ref([])
const streetName = ref('')
const communityArr = ref([])
const communityName = ref('')
const streetIndex = ref(0)
const communityIndex = ref()
const showData = ref({})
const step = ref(1)
const projectArr = ref([])

const formRef = ref()

// 获取区域下级信息
async function getFindGuidepriceByDistrict(districtName) {
  const obj = {
    districtName
  }
  const res = await findGuidepriceByDistrict(obj)
  data.value = res
  // console.log(data.value)
  streetName.value = res[0].street
  // 默认第一个下级社区数据
  communityArr.value = res[0].community_list
  streetIndex.value = 0
  communityIndex.value = ''
  communityName.value = ''
  formData.searchInput = ''
  step.value = 1
}

// 街道改变监听
function streetChange(e) {
  streetIndex.value = e
  // 下标下级社区数据
  communityArr.value = data.value[e].community_list
  // console.log('街道改变监听')
  communityName.value = ''
  communityIndex.value = ''
  formData.searchInput = ''
  // console.log(communityArr.value)
  step.value = 1
}

// 社区改变监听
function communityChange(e) {
  // console.log('社区改变监听')
  communityIndex.value = e
  formData.searchInput = ''
  // console.log(communityArr.value[e])
  step.value = 2
  getFindGuidepriceByCommunity(communityArr.value[e].community)
}

// 查询小区列表
async function getFindGuidepriceByCommunity(communityName) {
  const obj = {
    communityName
  }
  const res = await findGuidepriceByCommunity(obj)
  // console.log('小区列表')
  // console.log(res)
  projectArr.value = res
}

// 小区下拉选择事件
function searchChange(e) {
  console.log(e)
  // formData.searchInput = projectArr.value[e].project_name
}

// 搜索小区数据
async function search() {
  const obj = {
    projectName: formData.searchInput
  }
  const result = await findGuidepriceByProject(obj)
  return result
}

const countNum = ref(3)
const count = ref(0)

// 获取小区查询次数
async function getCount() {
  const obj = {}
  const res = await countGuidepriceByProject(obj)
  // console.log('获取小区查询次数')
  // console.log(res)
  count.value = res.count
}

const isShow = ref(false)

const tranfsData = (res) => {
  res.stairway_type_base = res.stairway_type_base
    ? res.stairway_type_base + '    元/建筑面积平方米·月'
    : '暂无'
  res.collective_housing_type_base = res.collective_housing_type_base
    ? res.collective_housing_type_base + '    元/建筑面积平方米·月'
    : '暂无'
  res.lift_type_base = res.lift_type_base
    ? res.lift_type_base + '    元/建筑面积平方米·月'
    : '暂无'
  return res
}

// 查询展示数据
async function find() {
  formRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      if (!formData.searchInput && !communityName.value) {
        isShow.value = true
        showData.value = tranfsData(data.value[streetIndex.value])
        return
      }
      if (!formData.searchInput && communityName.value) {
        isShow.value = true
        showData.value = tranfsData(communityArr.value[communityIndex.value])
        return
      }

      await getCount()
      let res = await search()
      res = tranfsData(res)
      isShow.value = true
      showData.value = res
    } catch (error) {
      if (step.value == 1) {
        showData.value = data.value[streetIndex.value]
      } else {
        showData.value = communityArr.value[communityIndex.value]
      }
    }
  })
}
</script>

<style lang='scss' scoped>
.link {
  width: 70px;
}

.butModel {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: #666;
  justify-content: center;
}

.count {
  color: red;
  margin: 0 5px;
}

.but {
  margin-top: 10px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  // height: 150px;
  flex-wrap: wrap;
}

.m-2 {
  margin: 10px 10px;
}

.required {
  color: #ff0000;
}

::v-deep .el-collapse-item__header {
  background: #f5f7fa;
  width: 100%;
  padding: 0 10px;
}

::v-deep .el-collapse-item__content {
  margin: 22px 0;
  padding: 0 10px;
}

::v-deep .el-table {
  font-size: 13px;
}

.w-80 {
  width: 80px;
}

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.indent-wrapper {
  p {
    margin-left: 20px;
  }
}

.w-p-48 {
  width: 48%;
}

::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>
